// This is for users in the store front
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export interface UserInfo {
  name: string;
  email: string;
}

export type UserInfoStore = {
  userInfo: UserInfo | null;
  setUserInfo: (userInfo: UserInfo) => void;
  resetUserInfo: () => void;
};

export const useUserInfoStore = create<UserInfoStore>()(
  persist(
    (set) => ({
      userInfo: null,
      setUserInfo: (userInfo) => set({ userInfo }),
      resetUserInfo: () => set({ userInfo: null }),
    }),
    {
      name: "user-info-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
