import React, { useEffect, useState } from "react";
import PureBannerSection from "../components/PureBannerSection";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import BannerImage from "../assets/membership.jpg";
import BannerSection from "../components/BannerSection";
import { Helmet } from "react-helmet";
import { useUserLoginMutation } from "../api/Customer/Customer";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const userLogin = useUserLoginMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLogin = () => {
    // redirect to member dashboard
    try {
      userLogin
        .mutateAsync({
          phone,
          password,
        })
        .catch((e) => {
          console.log(e);
          toast.error("密碼或電話錯誤！");
        });
    } catch (e) {
      console.log(e);
      toast.error("密碼或電話錯誤！");
    }
  };

  return (
    <>
      <Helmet>
        <title>登入 - 成為Big-O會員享受專屬服務</title>
        <meta
          name="description"
          content="登入您的Big-O會員帳號，管理您的訂單。"
        />
        <meta
          name="keywords"
          content="Big-O會員登入, 用戶登入, 會員專區, 帳號管理"
        />
      </Helmet>
      <BannerSection
        category=""
        title="登入會員"
        subtitle=""
        backgroundImage={BannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <div className="container">
          <ToastContainer position="top-right" autoClose={5000} />
          <Form>
            {/* title and sub title */}
            <h2>個人資訊</h2>
            <p>請輸入您的登入資訊</p>

            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>電話號碼</Form.Label>
              <Form.Control
                type="text"
                placeholder="請輸入電話號碼"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>密碼</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="請輸入密碼"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                  {/* Toggle icons */}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Button
                variant="link"
                onClick={() => {
                  window.location.href = "/forgot-password";
                }}
              >
                忘記密碼？
              </Button>
            </Form.Group>

            <Button
              variant="primary"
              disabled={!phone || !password}
              onClick={handleLogin}
            >
              立即登入
            </Button>
            <Button
              variant="link"
              style={{ color: "gray" }}
              onClick={() => {
                window.location.href = "/signup";
              }}
            >
              還沒有帳號？立即註冊
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
};

export default Login;
