import React, { useEffect, useState } from "react";
import PureBannerSection from "../components/PureBannerSection";
import { toast, ToastContainer } from "react-toastify";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BannerSection from "../components/BannerSection";
import BannerImage from "../assets/password.jpg";
import { useCreateResetPasswordTokenMutation } from "../api/Customer/Customer";

const ForgotPassword = () => {
  const [phone, setPhone] = useState("");
  const createResetPasswordToken = useCreateResetPasswordTokenMutation();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleForgotPassword = () => {
    createResetPasswordToken
      .mutateAsync({ phone })
      .then(() => {
        toast.success("驗證碼已寄送至您的手機");
      })
      .catch((e) => {
        toast.error("無法寄送驗證碼，請確認手機號碼");
      });
  };

  return (
    <>
      <BannerSection
        category=""
        title="忘記密碼？"
        subtitle=""
        backgroundImage={BannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="container">
          <Form>
            <h2>重設密碼</h2>
            <p>請輸入您的手機號碼，我們將寄送驗證碼至您的手機</p>

            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>手機號碼</Form.Label>
              <Form.Control
                type="phone"
                placeholder="請輸入手機號碼"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              disabled={!phone}
              onClick={handleForgotPassword}
            >
              重設密碼
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
