import React, { useEffect, useState } from "react";
import PureBannerSection from "../components/PureBannerSection";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import BannerSection from "../components/BannerSection";
import BannerImage from "../assets/membership.jpg";
import { Helmet } from "react-helmet";
import {
  useCreateVerifyPhoneTokenMutation,
  useCheckVerifyPhoneTokenMutation,
  useUserSignUpMutation,
} from "../api/Customer/Customer";

const SignUp = () => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const [canResend, setCanResend] = useState(false); // Control the resend button

  const { mutateAsync: createVerifyPhoneToken } =
    useCreateVerifyPhoneTokenMutation();
  const { mutateAsync: checkVerifyPhoneToken } =
    useCheckVerifyPhoneTokenMutation();
  const { mutateAsync: userSignup } = useUserSignUpMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (step === 2 && countdown > 0) {
      // Start countdown when step 2 is active and countdown is greater than 0
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      // Enable resend button when countdown reaches 0
      setCanResend(true);
    }

    return () => clearInterval(timer); // Clear interval when component is unmounted or countdown is done
  }, [step, countdown]);

  const handleSignUp = () => {
    // check if email is valid
    if (!email.includes("@")) {
      setEmailError("請輸入有效的電子信箱");
      return;
    }
    // check if password is strong enough
    if (password.length < 8) {
      setPasswordError("密碼長度需至少8位");
      return;
    }
    if (!/[A-Z]/.test(password)) {
      setPasswordError("密碼需包含至少一個大寫字母");
      return;
    }
    if (!/[a-z]/.test(password)) {
      setPasswordError("密碼需包含至少一個小寫字母");
      return;
    }
    if (!/[0-9]/.test(password)) {
      setPasswordError("密碼需包含至少一個數字");
      return;
    }
    if (!/[!@#$%^&*]/.test(password)) {
      setPasswordError("密碼需包含至少一個特殊字符");
      return;
    }

    // redirect to member dashboard
    setEmailError("");
    setPasswordError("");
    userSignup({
      phone: phoneNumber,
      password,
      name: username,
      email,
      phoneVerificationTokens: verificationCode,
    });
  };

  const handleNextStep = async () => {
    if (step === 1 && isAgreed && isAgreedToTerms && phoneNumber) {
      // create verification code here
      console.log("Creating verification code...");
      createVerifyPhoneToken({
        phone: phoneNumber,
      })
        .then(() => {
          setStep(2);
        })
        .catch((error) => {
          toast.error(
            "無法建立新的驗證碼，每個門號 5分鐘只能申請一次，每天最多 3次，請稍後再試。或是您的號碼已經註冊會員。"
          );
        });
    } else if (step === 2 && verificationCode) {
      // Handle verification completion here
      checkVerifyPhoneToken({
        phone: phoneNumber,
        token: verificationCode,
      })
        .then(() => {
          setStep(3);
        })
        .catch((error) => {
          toast.error("驗證碼錯誤");
        });
    } else if (step === 3 && username && password) {
      // Handle sign up completion here
      // redirect to member dashboard
    }
  };

  const handleResendCode = () => {
    // Logic to resend verification code goes here
    alert("Verification code resent.");
    setCountdown(300); // Reset the countdown to 5 minutes
    setCanResend(false); // Disable resend button
  };

  // Convert countdown from seconds to minutes:seconds format
  const formatCountdown = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <>
      <Helmet>
        <title>註冊 - 成為Big-O會員享受專屬服務</title>
        <meta
          name="description"
          content="註冊您的Big-O會員帳號，管理您的訂單。"
        />
        <meta
          name="keywords"
          content="Big-O會員註冊, 用戶註冊, 會員專區, 帳號管理"
        />
      </Helmet>
      <BannerSection
        category=""
        title="註冊會員"
        subtitle=""
        backgroundImage={BannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <div className="container">
          <ToastContainer position="top-right" autoClose={5000} />
          {/* step 1 */}
          {step === 1 && (
            <Form>
              {/* title and sub title */}
              <h2>註冊會員</h2>
              <Form.Group className="mb-3" controlId="phoneNumber">
                <Form.Label>手機</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="請輸入手機號碼"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="terms">
                <Form.Check
                  type="checkbox"
                  label="我願意接收Big-O的最新優惠消息及服務推廣相關資訊"
                  checked={isAgreed}
                  onChange={(e) => setIsAgreed(e.target.checked)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="agreeToTerms">
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      我已閱讀並同意
                      <a
                        href="/terms-Conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Big-O會員暨系統維運服務條款
                      </a>{" "}
                      及
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        隱私權聲明Privacy Policy
                      </a>
                    </>
                  }
                  checked={isAgreedToTerms}
                  onChange={(e) => setIsAgreedToTerms(e.target.checked)}
                />
              </Form.Group>

              <Button
                variant="primary"
                disabled={!isAgreed || !isAgreedToTerms || !phoneNumber}
                onClick={handleNextStep}
              >
                下一步
              </Button>

              {/* title and sub title */}
              <div className="mt-5">
                <h2>已經有帳號?</h2>
                <p>立即登入！</p>
                <Button
                  variant="outline-primary"
                  // navigate to login page
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                >
                  登入
                </Button>
              </div>
            </Form>
          )}
          {/* step 2 */}
          {step === 2 && (
            <Form>
              {/* title and sub title */}
              <h2>身份驗證</h2>
              <p>請輸入您收到的簡訊驗證碼</p>
              {/* a counter from 5 minutes to 0 */}
              <p>
                請於{" "}
                <span style={{ fontSize: 30 }}>
                  {formatCountdown(countdown)}
                </span>{" "}
                內輸入您收到的驗證碼
                <Button
                  variant="link"
                  disabled={!canResend}
                  onClick={handleResendCode}
                >
                  {canResend
                    ? "重新發送驗證碼"
                    : `重新發送驗證碼 (${formatCountdown(countdown)})`}
                </Button>
              </p>
              {/* Resend button */}

              {/* input for verification code */}
              <Form.Group className="mb-3" controlId="verificationCode">
                <Form.Label>驗證碼</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="6位數數字驗證碼"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="primary"
                disabled={!verificationCode}
                onClick={handleNextStep}
              >
                送出
              </Button>
            </Form>
          )}

          {/* step 3 */}
          {/* username and password */}
          {step === 3 && (
            <Form>
              {/* title and sub title */}
              <h2>個人資訊</h2>
              <p>請輸入您的個人資料，以完成註冊流程！</p>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>用戶名稱</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="請輸入用戶名稱"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>電子信箱</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="請輸入電子信箱"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  isInvalid={!!emailError}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>密碼</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="請輸入密碼"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                    }}
                    isInvalid={!!passwordError}
                  />
                  <InputGroup.Text
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                    {/* Toggle icons */}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Button
                variant="primary"
                disabled={!username || !password}
                onClick={handleSignUp}
              >
                立即加入
              </Button>
            </Form>
          )}
        </div>
      </section>
    </>
  );
};

export default SignUp;
